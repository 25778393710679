<template>
  <button
    :type="type"
    class="btn-primary"
    :style="radius ? `border-radius:${radius}px;` : ''"
    @click="btnClick"
  >
    <slot />
  </button>
</template>

<script>
import ripple from '@/directives/ripple';

export default {
  name: 'ButtonRipple',
  directives: { ripple },
  props: {
    type: {
      type: String,
      default: 'button',
    },
    to: {
      type: String,
      default: null,
    },
    radius: {
      type: Number,
      default: 0,
    },
  },
  methods: {
    btnClick() {
      if (this.to) {
        this.$router.push(this.to);
      } else {
        this.$emit('click');
      }
    },
  },
};
</script>

<style lang="less">
    button.btn-primary{
        width:240px;
        height:48px;
        font-size:1em;
        -webkit-tap-highlight-color: transparent;

        &::before {
            background-color: currentColor;
            border-radius: inherit;
            bottom: 0;
            color: inherit;
            content: "";
            left: 0;
            opacity: 0;
            pointer-events: none;
            position: absolute;
            right: 0;
            top: 0;
            transition: opacity .2s cubic-bezier(.4,0,.6,1);
        }

        &.dark{
            color:#fff;
            background-color:rgba(25, 25, 25, 1);
        }

        &.light{
            color:#000;
            background-color:rgba(255,255,255,1);
        }

        img{
            width:auto;
            height:auto;
            vertical-align:middle;
        }
    }
</style>
